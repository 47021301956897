<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">Determine the chemical name based on the chemical formulae given below:</p>

      <p class="mb-3">
        a)
        <chemical-latex :content="formulaA" />
      </p>
      <v-text-field v-model="inputs.compoundA" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        b)
        <chemical-latex :content="formulaB" />
      </p>
      <v-text-field v-model="inputs.compoundB" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        c)
        <chemical-latex :content="formulaC" />
      </p>
      <v-text-field v-model="inputs.compoundC" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        d)
        <chemical-latex :content="formulaD" />
      </p>
      <v-text-field v-model="inputs.compoundD" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        e)
        <chemical-latex :content="formulaE" />
      </p>
      <v-text-field v-model="inputs.compoundE" outlined class="mb-1" :disabled="!allowEditing" />

      <p class="mb-3">
        f)
        <chemical-latex :content="formulaF" />
      </p>
      <v-text-field v-model="inputs.compoundF" outlined class="mb-1" :disabled="!allowEditing" />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question216',
  components: {
    ChemicalNotationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        compoundA: null,
        compoundB: null,
        compoundC: null,
        compoundD: null,
        compoundE: null,
        compoundF: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    formulaA() {
      if (this.versionVariableA.value === 1) {
        return 'Ca(H2PO4)2';
      } else if (this.versionVariableA.value === 2) {
        return 'Ba(H2PO4)2';
      } else if (this.versionVariableA.value === 3) {
        return 'Na(H2PO4)';
      } else if (this.versionVariableA.value === 4) {
        return 'Li(H2PO4)';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    formulaB() {
      if (this.versionVariableB.value === 1) {
        return 'FeSO4';
      } else if (this.versionVariableB.value === 2) {
        return 'FePO4';
      } else if (this.versionVariableB.value === 3) {
        return 'CuSO4';
      } else if (this.versionVariableB.value === 4) {
        return 'CuNO3';
      } else {
        return '';
      }
    },
    versionVariableC() {
      return this.taskState.getValueBySymbol('versionVariableC').content;
    },
    formulaC() {
      if (this.versionVariableC.value === 1) {
        return 'CaCO3';
      } else if (this.versionVariableC.value === 2) {
        return 'Na2CO3';
      } else if (this.versionVariableC.value === 3) {
        return 'Na2SO4';
      } else if (this.versionVariableC.value === 4) {
        return 'CaSO4';
      } else {
        return '';
      }
    },
    versionVariableD() {
      return this.taskState.getValueBySymbol('versionVariableD').content;
    },
    formulaD() {
      if (this.versionVariableD.value === 1) {
        return 'MgO';
      } else if (this.versionVariableD.value === 2) {
        return 'CaO';
      } else if (this.versionVariableD.value === 3) {
        return 'Li2O';
      } else if (this.versionVariableD.value === 4) {
        return 'K2O';
      } else {
        return '';
      }
    },
    versionVariableE() {
      return this.taskState.getValueBySymbol('versionVariableE').content;
    },
    formulaE() {
      if (this.versionVariableE.value === 1) {
        return 'NaNO2';
      } else if (this.versionVariableE.value === 2) {
        return 'NaNO3';
      } else if (this.versionVariableE.value === 3) {
        return 'Ca(NO3)2';
      } else if (this.versionVariableE.value === 4) {
        return 'Ca(NO2)2';
      } else {
        return '';
      }
    },
    versionVariableF() {
      return this.taskState.getValueBySymbol('versionVariableF').content;
    },
    formulaF() {
      if (this.versionVariableF.value === 1) {
        return 'KF';
      } else if (this.versionVariableF.value === 2) {
        return 'NaF';
      } else if (this.versionVariableF.value === 3) {
        return 'NaCl';
      } else if (this.versionVariableF.value === 4) {
        return 'BaCl2';
      } else {
        return '';
      }
    },
  },
};
</script>
